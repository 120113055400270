import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import { injectIntl, intlShape } from 'react-intl';
import analytics from '../lib/analytics';
import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {
    getIsError,
    getIsShowingProject
} from '../reducers/project-state';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary
} from '../reducers/modals';

import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import SBFileUploaderHOC from '../lib/sb-file-uploader-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import TitledHOC from '../lib/titled-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import { setIsScratchDesktop } from '../lib/isScratchDesktop.js';

class GUI extends React.Component {
    componentDidMount() {
        setIsScratchDesktop(this.props.isScratchDesktop);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);
        // this.loadData("https://bth.educg.net/scrach.sb3")
        const that = this;
        window.sessionStorage.setItem('tabZX',false)
        //  console.log(window.parent.frames[0].document.URL)
        window.sessionStorage.setItem('fileUploadCanvas',true)
        var uu = '';
        if (window.parent.frames[0]) {
            uu = window.parent.frames[0].document.URL
        }
        else {
            uu = window.parent.frames.document.URL
        }
        //   uu = `https://scratch.educg.net/?cgtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybW9iaWxlIjoiIiwiZXhwIjoxNjk3MjkyOTA3LCJzdGFydGRhdGUiOiIxNjk3Mjc4NTA3NzY5IiwidXNlcmVtYWlsIjoic2FtcGxlVXNlckBlZHVjZy5uZXQiLCJkdWVkYXRlIjoiMTY5NzI4MDMwNzc2OSIsInVzZXJpZCI6InNhbXBsZVVzZXIiLCJxaWQiOiIxNjU1NDQ2MDU0NTQyIiwiam9iaWQiOiJCZGZqdm94VlNPUSIsInZlcnNpb24iOiIxLjAiLCJtb2RlIjoiMSJ9.i8NY0rxWhAZIARHMTw6HN9AxnBi2loinbM9POc1k9OY`
        function getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = uu.substr(1).match(reg);
            if (r != null) return decodeURI(r[2]); return null;
        }
        window.onload=function(){
            window.sessionStorage.setItem('_id', '');
            console.log('初始化_id为空');
        // Tcty练习过来的
        if (uu.indexOf('TcTylianxi') > 0) {
            // 做题状态下的保存
            let qid=getQueryString('qid');
            let userid=getQueryString('userid');
            let jobid=getQueryString('jobid');
            window.sessionStorage.setItem('mode',0)
            window.sessionStorage.setItem('TcTylianxi', 'true');
            let currentObj={
                qid:qid,
                userid:userid,
                jobid:jobid

            }
            window.sessionStorage.setItem('jwt', JSON.stringify(currentObj))
            //  let objNum = getQueryString(uu);
           
            var xhttp = new XMLHttpRequest();
            var timeout = false;
            var timer = setTimeout( function(){
                timeout = true;
                alert('网络超时，请检查网络后重新加载！')
                
            }, 3000 );
            

            // xhttp.onreadystatechange = function () {
            //     if( timeout ) return;
            //     if (this.readyState == 4 && this.status == 200) {
            //         clearTimeout( timer );
            //         //  document.getElementById("demo").innerHTML = this.responseText;
            //         // console.log(JSON.parse(this.responseText))
            //         const getData = JSON.parse(this.responseText);
            //         if (getData.data) {
            //             that.loadData(getData.data.url);

            //         }
            //         else {
                          
            //         }

            //     }
            // };
            // xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getProject.do', true);
            // //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getProject.do', true);
            // xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            // // xhttp.send(JSON.stringify(tempdata));
            // xhttp.send(`qid=${qid}`);

            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    clearTimeout( timer );
                    // that.loadData("https://bth.educg.net/scratchapi/getProjectfile.out")
                    // document.getElementById("demo").innerHTML = this.responseText;
                    // console.log(JSON.parse(this.responseText))
                    const getData = JSON.parse(this.responseText);
                    if (getData.data) {
                        window.sessionStorage.setItem('_id', getData.data._id);
                        // that.loadData('https://bth.educg.net'+getData.data.url)
                        that.loadData(getData.data.url)
                      
                    }
                    else {
                        // window.sessionStorage.setItem('_id', '');
                        // xhttp.onreadystatechange = function () {
                        //     if (this.readyState == 4 && this.status == 200) {
                        //         //  document.getElementById("demo").innerHTML = this.responseText;
                        //         // console.log(JSON.parse(this.responseText))
                        //         const getData = JSON.parse(this.responseText);
                        //         if (getData.data) {
                        //             // window.localStorage.setItem('_id', '');
                                   
                        //             that.loadData(getData.data.url);

                        //         }
                        //         else {

                        //         }

                        //     }
                        // };
                        // xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getProject.do', true);
                        // //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getProject.do', true);
                        // xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                        // // xhttp.send(JSON.stringify(tempdata));
                        // xhttp.send(`qid=${qid}`);
               

                    }

                }
            };
            // this.loadData("https://bth.educg.net/scratchapi/getProjectfile.out")
            xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getSelfProject.do', true);
            //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getSelfProject.do', true);
            xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            // xhttp.send(JSON.stringify(tempdata));
            xhttp.send(`qid=${qid}&userid=${userid}&jobid=${jobid}`);
        }
        else {
            window.sessionStorage.setItem('TcTylianxi', 'false')
            const cgtoken = uu.split('=')[1];
            const base64Url = cgtoken.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            const tokenObj = JSON.parse(jsonPayload);
            // 根据mode 判断类型
            //tokenObj.mode =2; //Number(tokenObj.qid);
            console.log(tokenObj);
            //修改123
            // tokenObj.mode=2
            window.sessionStorage.setItem('mode', tokenObj.mode)
            window.sessionStorage.setItem('jwt', JSON.stringify(tokenObj))
            // 恢复初始化素材
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    //  document.getElementById("demo").innerHTML = this.responseText;
                    // console.log(JSON.parse(this.responseText))
                    const getData = JSON.parse(this.responseText);
                    console.log('测试进来！')
                    // window.sessionStorage.setItem('_id', '');
                    if (getData.data) {
                        // window.sessionStorage.setItem('_id', '');
                        // that.loadData(getData.data.url);
                        window.sessionStorage.setItem('anjing_loadAata',getData.data.url)

                    }
                    else {
                      
                    }

                }
            };
            
            xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getProject.do', true);
            //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getProject.do', true);
            xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            // xhttp.send(JSON.stringify(tempdata));
            xhttp.send(`qid=${tokenObj.qid}&newqid=${tokenObj.newqid || ''}`);
            
    // 恢复初始化素材

            if (tokenObj.mode == 0) {
                console.log('mode0')
                // 做题表 ---调取查询接口  查询上一次提交的题或者 出题
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        // that.loadData("https://bth.educg.net/scratchapi/getProjectfile.out")
                        // document.getElementById("demo").innerHTML = this.responseText;
                        // console.log(JSON.parse(this.responseText))
                        const getData = JSON.parse(this.responseText);
                        if (getData.data) {
                            console.log('mode0正常')
                            window.sessionStorage.setItem('_id', getData.data._id);
                            // that.loadData('https://bth.educg.net'+getData.data.url)
                            that.loadData(getData.data.url)
                      
                        }
                        else {
                            xhttp.onreadystatechange = function () {
                                if (this.readyState == 4 && this.status == 200) {
                                    //  document.getElementById("demo").innerHTML = this.responseText;
                                    // console.log(JSON.parse(this.responseText))
                                    const getData = JSON.parse(this.responseText);
                                    // window.sessionStorage.setItem('_id', '');
                                    if (getData.data) {
                                        // window.sessionStorage.setItem('_id', '');
                                        that.loadData(getData.data.url);
                                    

                                    }
                                    else {
                                        console.log('mode0异常')
                                        // alert('网络出现异常，请尝试刷新！') 
                                      
                                    }

                                }
                            };
                            xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getProject.do', true);
                            //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getProject.do', true);
                            xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                            // xhttp.send(JSON.stringify(tempdata));
                            xhttp.send(`qid=${tokenObj.qid}&newqid=${tokenObj.newqid || ''}`);

                        }

                    }
                };
                // this.loadData("https://bth.educg.net/scratchapi/getProjectfile.out")
                xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getSelfProject.do', true);
                //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getSelfProject.do', true);
                xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                // xhttp.send(JSON.stringify(tempdata));
                xhttp.send(`qid=${tokenObj.qid}&userid=${tokenObj.userid}&jobid=${tokenObj.jobid}`);

            }

            else if (tokenObj.mode == 2) {
                console.log('mode22')
                // 出题表--查询接口--查询出题数据
                // // https://bth.educg.net/scratchapi/fileUpload.do
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        //  document.getElementById("demo").innerHTML = this.responseText;
                        // console.log(JSON.parse(this.responseText))
                        const getData = JSON.parse(this.responseText);
                        if (getData.data) {
                            console.log('mode2正常')
                            window.sessionStorage.setItem('_id', getData.data._id);
                            that.loadData(getData.data.url);
               

                        }
                        else {
                            console.log('mode2异常')
                            // alert('网络出现异常，请尝试刷新！') 
                            // window.sessionStorage.setItem('_id', '');
                           
                        }

                    }
                };
                xhttp.open("POST", ' https://fs.api.eduxiji.net/scratchapi/getProject.do', true);
                //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getProject.do', true);
                xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                // xhttp.send(JSON.stringify(tempdata));
                xhttp.send(`qid=${tokenObj.qid}&newqid=${tokenObj.newqid || ''}`);


            }
            else if (tokenObj.mode == 1) {
                // 只读：评阅的学生题目和评阅题目的参考答案
                console.log('mode1')
                // 做题表 ---调取查询接口  查询上一次提交的题或者 出题
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        // that.loadData("https://bth.educg.net/scratchapi/getProjectfile.out")
                        // document.getElementById("demo").innerHTML = this.responseText;
                        // console.log(JSON.parse(this.responseText))
                        const getData = JSON.parse(this.responseText);
                        if (getData.data) {
                            window.sessionStorage.setItem('_id', getData.data._id);
                            // that.loadData('https://bth.educg.net'+getData.data.url)
                            that.loadData(getData.data.url)
                    
                        }
                        else {
                            xhttp.onreadystatechange = function () {
                                if (this.readyState == 4 && this.status == 200) {
                                    //  document.getElementById("demo").innerHTML = this.responseText;
                                    // console.log(JSON.parse(this.responseText))
                                    const getData = JSON.parse(this.responseText);
                                    console.log('测试进来2')
                                    // window.sessionStorage.setItem('_id', '');
                                    if (getData.data) {
                                        console.log('mode1正常')
                                        that.loadData(getData.data.url);
                                    

                                    }
                                    else {
                                        console.log('mode1异常')
                                        // alert('网络出现异常，请尝试刷新！') 
                                        // window.sessionStorage.setItem('_id', '');
                                    }

                                }
                            };
                            xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getProject.do', true);
                            //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getProject.do', true);
                            xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                            // xhttp.send(JSON.stringify(tempdata));
                            xhttp.send(`qid=${tokenObj.qid}&newqid=${tokenObj.newqid || ''}`);

                        }

                    }
                };
                // this.loadData("https://bth.educg.net/scratchapi/getProjectfile.out")
                xhttp.open("POST", 'https://fs.api.eduxiji.net/scratchapi/getSelfProject.do', true);
                //xhttp.open("POST", 'https://bth.educg.net/scratchapi/getSelfProject.do', true);
                xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                // xhttp.send(JSON.stringify(tempdata));
                xhttp.send(`qid=${tokenObj.qid}&userid=${tokenObj.userid}&jobid=${tokenObj.jobid}`);
                // window.localStorage.setItem('_id', '');
            }

        }
    }



        // this.setState({
        //     qid: tokenObj.qid,
        //     usermobile: tokenObj.usermobile,

        // }, () => {
        //     console.log(this.state.qid)
        //     var autoMessage = { score: 100 }//this.state;
        //     window.parent.postMessage(autoMessage, '*')
        // });
        // console.log(this.state.qid)
        //首次加载
        // this.loadData('https://static.educg.net/seartchWeb/static/222.sb3')

        // console.log(1)

    }
    //     handgetCgToken(bsl) {
    //         const that = this;
    //         // console.log(window.parent.frames[0].document.URL)
    //         // mode=0--出题   https://static.educg.net/seartchWeb/index.html?cgtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybW9iaWxlIjoiMTM4MDAxMzgwMDAiLCJleHAiOjE2NDM0NTM2MDQsInN0YXJ0ZGF0ZSI6IjE2NDM0NDI2ODQ0NjEiLCJ1c2VyZW1haWwiOiJzYW1wbGVUZXN0VXNlckBlZHVjZy5uZXQiLCJkdWVkYXRlIjoiMTY0MzQ0NDAwNDQ2MSIsInVzZXJpZCI6InNhbXBsZVRlc3RVc2VyIiwicWlkIjoiMCIsImpvYmlkIjoibTFaMEJiTUNxMk5teTNIa3oxNlVmR0JfU2owb0hucm8iLCJ2ZXJzaW9uIjoiMS4wIn0.YNyaB5O-yeeJHS32BdC17DUp12nLsqpVDcWVgLjleLQ
    //         // mode=1--老师创建 https://static.educg.net/seartchWeb/index.html?cgtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybW9iaWxlIjoiMTM4MDAxMzgwMDAiLCJleHAiOjE2NDM0NTM2NjAsInN0YXJ0ZGF0ZSI6IjE2NDM0NDI3NDAwNzciLCJ1c2VyZW1haWwiOiJzYW1wbGVUZXN0VXNlckBlZHVjZy5uZXQiLCJkdWVkYXRlIjoiMTY0MzQ0NDA2MDA3NyIsInVzZXJpZCI6InNhbXBsZVRlc3RVc2VyIiwicWlkIjoiMSIsImpvYmlkIjoibTFaMEJiTUNxMk5teTNIa3oxNlVmR0JfU2owb0hucm8iLCJ2ZXJzaW9uIjoiMS4wIn0.zE_sABZK7AZTo5VLndilhdtH94yVbdJoEjvLCp4crKI
    //         // mode=2--老师编辑  https://static.educg.net/seartchWeb/index.html?cgtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybW9iaWxlIjoiMTM4MDAxMzgwMDAiLCJleHAiOjE2NDM0NTM2OTMsInN0YXJ0ZGF0ZSI6IjE2NDM0NDI3NzMwMTUiLCJ1c2VyZW1haWwiOiJzYW1wbGVUZXN0VXNlckBlZHVjZy5uZXQiLCJkdWVkYXRlIjoiMTY0MzQ0NDA5MzAxNSIsInVzZXJpZCI6InNhbXBsZVRlc3RVc2VyIiwicWlkIjoiMiIsImpvYmlkIjoibTFaMEJiTUNxMk5teTNIa3oxNlVmR0JfU2owb0hucm8iLCJ2ZXJzaW9uIjoiMS4wIn0.Lzkj--ZBgZaQ9oqhuVeczoqhm4rAJ1QGvAVuNS63qwg
    // //const uu = `https://static.educg.net/seartchWeb/index.html?cgtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybW9iaWxlIjoiMTM4MDAxMzgwMDAiLCJleHAiOjE2NDM0NTM2MDQsInN0YXJ0ZGF0ZSI6IjE2NDM0NDI2ODQ0NjEiLCJ1c2VyZW1haWwiOiJzYW1wbGVUZXN0VXNlckBlZHVjZy5uZXQiLCJkdWVkYXRlIjoiMTY0MzQ0NDAwNDQ2MSIsInVzZXJpZCI6InNhbXBsZVRlc3RVc2VyIiwicWlkIjoiMCIsImpvYmlkIjoibTFaMEJiTUNxMk5teTNIa3oxNlVmR0JfU2owb0hucm8iLCJ2ZXJzaW9uIjoiMS4wIn0.YNyaB5O-yeeJHS32BdC17DUp12nLsqpVDcWVgLjleLQ`
    //         const cgtoken = (window.parent.frames[0].document.URL).split('=')[1];
    //         const base64Url = cgtoken.split('.')[1];
    //         const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //         const jsonPayload = decodeURIComponent(
    //             atob(base64)
    //                 .split('')
    //                 .map(function (c) {
    //                     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //                 })
    //                 .join('')
    //         );
    //         const tokenObj = JSON.parse(jsonPayload);
    //         // 根据mode 判断类型
    //         tokenObj.mode =Number(tokenObj.qid);
    //         console.log(tokenObj);
    //         window.localStorage.setItem('mode',tokenObj.mode)
    //         window.localStorage.setItem('jwt',JSON.stringify(tokenObj))
    //         fetch('https://static.educg.net/seartchWeb/static/222.sb3', {
    //             method: 'GET'
    //         })
    //             .then(response => response.blob())
    //             .then(blob => {
    //                 const reader = new FileReader();
    //                 reader.onload = () => this.props.vm.loadProject(reader.result)
    //                     .then(() => {
    //                         GoogleAnalytics.event({
    //                             category: 'project',
    //                             action: 'Import Project File',
    //                             nonInteraction: true
    //                         })
    //                     })
    //                 reader.readAsArrayBuffer(blob)
    //             })
    //             .catch(error => {
    //                 alert(`远程加载文件错误！${error}`)
    //             })

    //     }

    loadData(url) {
        fetch(url, {
            method: 'GET'
        })
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => this.props.vm.loadProject(reader.result)
                    .then(() => {

                        analytics.event({
                            category: 'project',
                            action: 'Import Project File',
                            nonInteraction: true
                        })
                    })
                reader.readAsArrayBuffer(blob)
            })
            .catch(error => {
                alert(`远程加载文件错误！${error}`)
            })
    }
    componentDidUpdate(prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();
        }
    }

    render() {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onVmInit,
            projectHost,
            projectId,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            ...componentProps
        } = this.props;
        return (
            <GUIComponent
                loading={fetchingProject || isLoading || loadingStateVisible}
                {...componentProps}
            >
                {children}
            </GUIComponent>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onVmInit: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    telemetryModalVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUI.defaultProps = {
    isScratchDesktop: false,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => { },
    onUpdateProjectId: () => { },
    onVmInit: (/* vm */) => { }
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm
    };
};

const mapDispatchToProps = dispatch => ({
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal())
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    TitledHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    SBFileUploaderHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
